<!-- Provides an interface through which new "Series" documents can be added to the database. -->
<template>
  <div class="form-container">
    <form>
      <v-select
        v-model="selectedSeries"
        outlined
        hide-details
        placeholder="Edit Existing Series"
        :items="allSeries"
        item-text="name"
        item-value="id"
        return-object
        @change="selectSeries"
      ></v-select>
      <TextInput
        v-model="series.name"
        label="Series Name"
        name="series-name"
        required
      />
      <TextInput
        v-model="series.id"
        label="Series ID"
        name="series-id"
        required
      />
      <DatePicker v-model="series.releaseDate" placeholder="Release Date" />
      <v-select
        v-model="seriesCollectionGroup"
        :items="collectionGroups || []"
        item-text="name"
        item-value="id"
        return-object
        hide-details
        label="Associated Collection Group"
      />
      <TextArea
        v-model="series.description"
        label="Series Description"
        name="series-description"
      />
      <MediaPicker
        v-model="seriesImage"
        label="Series Image"
        required
      />
    </form>

    <div class="submit-buttons">
      <button class="standard" type="button" @click="addSeries">
        {{ editingSeries ? 'Save Edits' : 'Add New Series'}}
      </button>
      <ValidationErrors :errors="validationErrors" />
    </div>
  </div>
</template>

<script>
import { doc, setDoc } from 'firebase/firestore'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  async created () {
    await this.loadAllSeries(true)
  },
  data () {
    return {
      selectedSeries: null,
      editingSeries: false,
      series: {},
      seriesImage: null,
      seriesCollectionGroup: null,
      validationErrors: []
    }
  },
  computed: {
    ...mapGetters([
      'collectionGroups',
      'allSeries'
    ])
  },
  methods: {
    ...mapMutations([
      'setGlobalState',
      'setLoading'
    ]),
    ...mapActions([
      'loadAllSeries'
    ]),
    selectSeries () {
      this.editingSeries = true
      // Load details
      this.series = { ...this.selectedSeries }
      this.seriesCollectionGroup = this.selectedSeries.collectionGroup
    },
    async addSeries () {
      this.setLoading(true)
      if (this.seriesImage) {
        try {
          this.setGlobalState({ target: 'loadingStatus', val: 'Uploading series image asset. Please don\'t close this browser window.' })
          const res = await this.fbCall('cloudinary-uploadAsset', {
            path: `stores/${process.env.VUE_APP_MARKET_ID}/series/${this.series.id}/image`,
            asset: this.seriesImage.rawData
          })
          this.series.image = res.data.secure_url
        } catch (err) {
          console.log(err)
          this.setLoading(false)
          this.addNotification('Error uploading series image.', 'error')
          return
        }
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Adding new series to database. Please don\'t close this browser window.' })
        this.series.collectionGroup = { name: this.seriesCollectionGroup.name, id: this.seriesCollectionGroup.id }
        await setDoc(doc(this.$fb.db, 'series', this.series.id), this.series)
      } catch (err) {
        console.log(err)
        this.setLoading(false)
        this.addNotification('Error adding series.', 'error')
        return
      }
      this.setLoading(false)
      this.addNotification(`Successfully ${this.editingSeries ? 'updated' : 'added new'} series.`, 'success')
    }
  }
}
</script>

<style>

</style>
